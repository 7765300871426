<template>
  <div>
    <div class="j_datagrid__header type01">
      <div class="header__title">
        <h5>
          User Access History
          <small>{{ subtitle }}</small>
        </h5>
      </div>
      <button class="j_button type01 sky historyBtn" @click="excelExport">
        <i class="jcon_download_02"></i>
        EXCEL
      </button>
      <div class="header__right filterable">
        <div class="filter_wrapper">
          <!-- Single Selct -->
          <v-select
            v-model="selectedCompName"
            attach
            class="lnb_single_select company"
            content-class="lnb_dropdown"
            item-text="name"
            item-value="code"
            :items="companyOptions"
            :menu-props="{ maxHeight: '190', closeOnContentClick: true}"
            :placeholder="`Select User`"
            @input="onCompChanged"
            multiple
          ></v-select>
          <!-- Single Selct -->
          <v-select
            v-model="selectedUser"
            attach
            class="lnb_single_select user"
            content-class="lnb_dropdown"
            item-text="name"
            item-value="code"
            :items="userOptions"
            :menu-props="{ maxHeight: '190', closeOnContentClick: true}"
            :placeholder="`Select User`"
            @input="onUserSelected"
          ></v-select>
          <!-- Single Selct -->
          <!-- <v-select
            v-model="selectedPermit"
            attach
            class="lnb_single_select permit"
            content-class="lnb_dropdown"
            item-text="name"
            item-value="code"
            :items="permitOptions"
            :menu-props="{ maxHeight: '190', closeOnContentClick: true}"
            :placeholder="`Select Permit`"
            @input="onPermitSelected"
          ></v-select>           -->
        </div>
      </div>
    </div>

    <j-data-grid
      v-model="selected"
      class="chart"
      hide-actions
      :headers="headers"
      :items="items"
      :search="search"
    >
      <template v-slot:items="props">
        <tr :active="props.selected">
          <td style="text-align: center">{{ props.item.no }}</td>
          <td>{{ props.item.PHASE_NAME }}</td>
          <td>{{ props.item.GROUP_NAME }}</td>
          <td>{{ props.item.ITEM_NAME }}</td>
          <td>{{ props.item.SUBITEM_NAME }}</td>
          <td style="text-align: center">{{ props.item.LOCATIONS }}</td>
          <td style="text-align: center">{{ props.item.VISITORS }}</td>
          <td style="text-align: center">{{ props.item.VISITED }}</td>
          <td style="text-align: center">{{ props.item.AVG_STAYED }}</td>
          <td></td>
        </tr>
      </template>
    </j-data-grid>

  </div>
</template>

<script>
import "../../assets/stylus/ui/component/_user.access.history.styl"

import __C from '@/primitives/_constant_'
import Url from '../../../src/services/api/request.url'
import { mapState , mapActions} from 'vuex'
import { ApplicationService, SystemService, SystemCodeService } from '@/services'

export default {
  name: 'sysenv-user-access-history',
  data: () => ({
    appService: null,
    systemCodeService: null,
    systemService: null,
    headers: [
      { type: 'conf', text: 'No', align: 'center', value: 'no', sortable: false, width: 30, },
      { type: 'text', text: 'Phase', align: 'left', value: 'PHASE_NAME', sortable: false, width: 120, },
      { type: 'text', text: 'Group', align: 'left', value: 'GROUP_NAME', sortable: false, width: 200, },
      { type: 'text', text: 'Item', align: 'left', value: 'ITEM_NAME', sortable: false, width: 170, },
      { type: 'text', text: 'Sub-Item', align: 'left', value: 'SUBITEM_NAME', sortable: false, width: 170, },
      { type: 'text', text: 'Locations', align: 'center', value: 'LOCATIONS', sortable: false, width: 65, },
      { type: 'text', text: 'Visitors', align: 'center', value: 'VISITORS', sortable: false, width: 65, },
      { type: 'text', text: 'Hits', align: 'center', value: 'VISITED', sortable: false, width: 65, },
      { type: 'text', text: 'Stay (Avg.)', align: 'center', value: 'AVG_STAYED', sortable: false, width: 80, },
      { type: 'text', text: '', align: 'center', value: 'version', sortable: false },
    ],
    items: [],
    selected: [],
    
    selectedCompName: [],
    selectedUser: 'ALL',
    selectedPermit: 'ALL',

    companyOptions: [],
    userOptions: [],
    permitOptions: [],

    search: '',
  }),
  computed: {
    ...mapState(__C.STORE_NAMESPACE.ACCOUNT, ['account']),

    subtitle() { 
      if(this.$route.params.by == 'overview') return 'Overview'
      return ''
    }
  },
  created() {
    this.appService = new ApplicationService()
    this.systemService = new SystemService()
    this.systemCodeService = new SystemCodeService()
  },
  mounted() {

    // this.account.userPermit == 'SYS_ADMIN' ? 'Y' : 'N' => It should be check by server, not client
    // System work process should be updated later for security purpose.
    // All the security checking process must be run on the server side...

    this.systemCodeService.companies(
      this.account.userPermit == 'SYS_ADMIN' ? 'Y' : 'N',
      (res) => {
        if(!res) this.companyOptions = []
        else {

        let count = 0
        if(this.$route.path.includes('user_service/access_history')) {
          res.forEach((d,k) => {
            if (d.code == 'JGS INC.') {
              count = k
            }
          })
          res.splice(count,1)
        }

          this.companyOptions = [
            { name: 'All Companies', code: 'ALL' },
            ...res
          ]
          this.selectedCompName.push('ALL')
          // this.selectedCompName = 'ALL'
        }
      }
    )
    this.getCompUsers()
    // this.getPermit()
    this.getRequestHistory()
  },
  methods: {
    ...mapActions(__C.STORE_NAMESPACE.INTENDED_DATAGRID, [
      'GET_EXCEL',
    ]),
    onCompChanged(v) {
      if (v[0] == 'ALL' && v.length > 1) {
        this.selectedCompName.splice(0,1)
      } else if (v.length > 1 && v.includes('ALL') && v[0] != 'ALL') {
        this.selectedCompName = []
        this.selectedCompName.push('ALL')
      }

      this.selectedUser = ''
      this.selectedPermit = 'ALL'
      this.getCompUsers(v)
    },
    onUserSelected(v) {
      this.getRequestHistory()
    },
    onPermitSelected(v) {
      this.selectedUser = 'ALL'
      this.onUserSelected()      
    },
    getPermit(permit='ALL') {
      this.systemService.getCodeIndex('PERMISSION', res => {
        if(!res) this.permitOptions = []
          else {
            this.permitOptions = [
              { name: 'All Permit', code: 'ALL' },
              ...res
            ]
          }
          this.selectedPermit = 'ALL'
          this.getRequestHistory()
      })   
    },
    getRequestHistory() {
      let array = this.selectedCompName.length == 0 ? ['ALL'] : this.selectedCompName
      if (array[0] != 'ALL') {
        var arrary__ = []
        array.forEach(d => { arrary__.push(`'${d}'`) })
        array = arrary__
      } 
      let userID = `'${this.selectedUser}'`
      let params = new FormData()
      params.append("permit", this.account.userPermit == 'SYS_ADMIN' ? 'Y' : 'N')
      params.append("company", array.join(','))
      params.append("user", userID)
      this.appService.getRequestHistory(params).then(res => {
        this.items = res
      })      
      // this.appService.getRequestHistory(
      //   this.account.userPermit == 'SYS_ADMIN' ? 'Y' : 'N',
      //   this.selectedCompName, 
      //   this.selectedUser,
      //   this.selectedPermit
      // ).then(res => {
      //   this.items = res
      // })
    },
    getCompUsers(compName='ALL') {
      let array = this.selectedCompName.length == 0 ? ['ALL'] : this.selectedCompName
      if (array[0] != 'ALL') {
        var arrary__ = []
        array.forEach(d => { arrary__.push(`'${d}'`) })
        array = arrary__
      } 
      let params = new FormData()
      params.append("permit", this.account.userPermit == 'SYS_ADMIN' ? 'Y' : 'N')
      params.append("company", array.join(','))
      this.systemCodeService.compUsers(params).then(res => {
        if (!res) this.userOptions = [] 
        else {
          this.userOptions = [
            { name: 'All Users', code: 'ALL' },
            ...res
          ]
          this.selectedUser = 'ALL'
          this.getRequestHistory()          
        } 
      })
      // this.systemCodeService.compUsers(
      //   compName, this.account.userPermit == 'SYS_ADMIN' ? 'Y' : 'N' 
      //   || this.selectedCompName, this.account.userPermit == 'SYS_ADMIN' ? 'Y' : 'N').then(res => {
      //   if(!res) this.userOptions = []
      //   else {
      //     this.userOptions = [
      //       { name: 'All Users', code: 'ALL' },
      //       ...res
      //     ]
      //   }
      //   this.selectedUser = 'ALL'
      //   this.getRequestHistory()
      // })
    },
    async excelExport() {

      let params = new URLSearchParams()
      params.append('items', JSON.stringify(this.items)) 
      params.append('headers',JSON.stringify(this.headers))
      params.append('token', this.account.token)

      const send_Data = {
        url_ : `${Url.fetch.excel.history}?${params.toString()}`,
        title : 'User Access History'
      }

      await this.GET_EXCEL(send_Data)
    }
  }
}
</script>
